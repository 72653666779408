/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            linkedin
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/profile-pic.png"
        width={50}
        height={50}
        quality={95}
        alt="Profile picture"
      />
      {author?.name && (
        <p>
          Thank you for reading this article!
          This article is written by Wataru Hirota (email: &#123;my_first_name&#125;@whiro.me).
          Please follow me on&nbsp;
          <OutboundLink href={`https://x.com/${social.twitter}`}>
            X
          </OutboundLink>&nbsp;
          or connect via&nbsp;
          <OutboundLink href={`https://linkedin.com/in/${social.linkedin}`}>
            LinkedIn
          </OutboundLink>&nbsp;
          if you're interested in articles like this. :)
          {` `}

        </p>
      )}
    </div>
  )
}

export default Bio
